// Based on https://github.com/alphagov/govuk_publishing_components/blob/v22.0.0/app/assets/stylesheets/govuk_publishing_components/components/_step-by-step-nav-header.scss
// Note - this code for prototype purposes only. It is not production code.

.app-step-nav-header {
  @include govuk-text-colour;
  position: relative;
  padding: 10px;
  background: govuk-colour("light-grey", $legacy: "grey-4");
  border-top: solid 1px govuk-colour("mid-grey", $legacy: "grey-2");
  border-bottom: solid 1px govuk-colour("mid-grey", $legacy: "grey-2");

  @include govuk-media-query($from: tablet) {
    padding: govuk-spacing(3);
  }
}

// scss-lint:disable SelectorFormat

.app-step-nav-header__part-of {
  @include govuk-font(16, $weight: bold);

  display: block;
  padding-bottom: .2em;
}

.app-step-nav-header__title {
  @extend %govuk-link;
  @include govuk-font(24, $weight: bold);
}

// scss-lint:enable SelectorFormat
