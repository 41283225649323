// global styles for <a> and <p> tags
$govuk-global-styles: true;
$govuk-new-link-styles: true;

// We can't mount GOV.UK Frontend's assets at root as it's done automatically by the extensions framework.
$govuk-assets-path: '/govuk/assets/';

// Import GOV.UK Frontend and any extension styles if extensions have been configured
@import "lib/extensions/extensions";

// Patterns that aren't in Frontend
@import "patterns/step-by-step-header";
@import "patterns/step-by-step-nav";
@import "patterns/step-by-step-related";
@import "patterns/task-list";
@import "patterns/related-items";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@400;500;700&display=swap');

// Add extra styles here, or re-organise the Sass files in whichever way makes most sense to you
/** Abisola overrides **/
a, p, blockquote, pre, small, strike, strong, sub, sup, tt, var, b, u, i, center, input,
textarea, table, caption, tbody, tfoot, thead, tr, th, td, .govuk-body {
    font-size: inherit;
    font-family: 'Quicksand', 'Raleway', 'Roboto', sans-serif!important;
}

.govuk-hint, {
  font-family: 'Quicksand', 'Raleway', 'Roboto', sans-serif!important;
}

.govuk-label {
  font-family: 'Quicksand', 'Raleway', 'Roboto', sans-serif!important;
  font-weight: 600!important;
}

h1, h2, h3, h4, h5, h6, .govuk-header__link--service-name {
  font-family: 'Roboto', 'Rubik', 'Roboto', sans-serif!important;
}

// Overrides
.govuk-button {
  font-family: 'Roboto', 'Rubik', 'Roboto', sans-serif!important;
}

.govuk-header__container {
  border-bottom: 10px solid white;
}
.govuk-header {
  background-color: slategray;
}
.govuk-header__logo {
  visibility: hidden;
  display: none;
}

.govuk-header__content {
  padding-left: 0;
}
.govuk-footer__licence-logo {
  display: none;
}

.govuk-footer__licence-description {
  display: none;
}

.govuk-footer__copyright-logo {
  display: none;
}

p {
  font-weight: 500!important;
  font-size: 1.3rem!important;
}

h2 {
  font-size: 1.5rem!important;
}
.govuk-box-highlight{
  margin: 2em 1em;
}

.heading-xlarge {
  margin-top: 2rem;
}

strong {
  font-weight: 600;
}
/** end **/

// Add extra styles here, or re-organise the Sass files in whichever way makes most sense to you
.wrap {
  white-space: pre-wrap;
}

#logo-af {
  float: left;
    overflow: visible;
    color: white;
    font-weight: bold;
    font-size: 30px;
    line-height: 1;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    border-bottom: 1px solid transparent;
}

.bus-service-list {
  width: 50%;
  font-size: 1.125rem;
  font-family: 'Quicksand', 'Raleway', 'Roboto', sans-serif!important;
}

.bus-service-list li {
    margin-bottom: 1.2rem;
}
